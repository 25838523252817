
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React, { useState } from "react";
import Image from "next/image";
import { useCookie } from "next-cookie";
import { useRouter } from "next/router";
import HeaderSearch from "../component/common/HeaderSearch";
import analytic from "../utils/analytic";
import Head from "next/head";
import { TRACK_LOGIN_SUCCESS } from "../utils/trackEvent";
import { useStateContext } from "../context";
import FooterAppAds from "../component/common/FooterAppAds";
import Footer from "../component/common/Footer";
import EyeIcon from "@heroicons/react/solid/EyeIcon";
import EyeOffIcon from "@heroicons/react/solid/EyeOffIcon";
import { toast } from "react-toastify";
import useTranslation from "next-translate/useTranslation";
import Link from "next/link";
const Login = (props) => {
    const { t } = useTranslation();
    const cookie = useCookie(props.cookie);
    const [emailInput, setEmail] = useState("");
    const [passwordInput, setPassword] = useState("");
    const [isSubmiting, setIsSubmiting] = useState(false);
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const router = useRouter();
    const [state, setState] = useStateContext();
    const onEmailChange = (e) => {
        setEmail(e.target.value);
    };
    const onPasswordChange = (e) => {
        setPassword(e.target.value);
    };
    const togglePasswordVisibility = () => {
        setIsPasswordVisible((prevState) => !prevState);
    };
    const onLoginFormSubmit = async (e) => {
        e.preventDefault();
        setIsSubmiting(true);
        try {
            const response = await fetch(`${process.env.NEXT_PUBLIC_BASE_ADMIN_URL}/api/login`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    email: emailInput,
                    password: passwordInput
                })
            });
            const data = await response.json();
            if (data.status) {
                setState({ ...state, token: data.data.access_token });
                cookie.set("user_id", data.data.userid, { path: "/" });
                cookie.set("token", data.data.access_token, { path: "/" });
                analytic.setUserId(data.data.userid);
                TRACK_LOGIN_SUCCESS();
                if (typeof router.query.continue !== "undefined") {
                    router.push(router.query.continue);
                }
                else {
                    router.push("/");
                }
            }
            else {
                toast.error(data.messages.join(",") || t("login failed please try again"));
            }
        }
        catch (error) {
            // Network or other errors
            toast.error(t("something went wrong"));
            console.error(error);
        }
        finally {
            setIsSubmiting(false);
        }
    };
    return (<div className="">
      <Head>
        <title>{t("common:login")} | Rumahdewi.com</title>
      </Head>

      <HeaderSearch />
      <div className="container d-flex align-items-center justify-content-center">
        <div className="row shadow rounded-4 my-4" style={{ width: "1000px" }}>
          <div className="col-12 col-md-8 position-relative" style={{ padding: "50px" }}>
            <div className="position-absolute top-0 end-0">
              <Image src="/assets/login-star.svg" width={70} height={70}/>
            </div>
            <div className="position-absolute bottom-0 start-0" style={{ transform: "rotate(180deg)" }}>
              <Image src="/assets/login-star.svg" width={70} height={70}/>
            </div>

            <div className="card-body">
              <h1 className="text-left font-roboto" style={{
            fontStyle: "italic",
            fontWeight: "600",
            background: "linear-gradient(80.27deg, #006EA7 43.69%, #009CED 66.15%, #006EA7 79.98%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            backgroundClip: "text",
            color: "transparent"
        }}>
                {t("common:login")}
              </h1>
              <p style={{
            fontSize: "15px",
            color: "#606060",
            marginBottom: "20px"
        }}>
                {t("loginRegister:login to your account")}
              </p>
              <form onSubmit={onLoginFormSubmit} className="position-relative">
                <div className="mb-4 position-relative">
                  <label htmlFor="email" className="position-absolute bg-white px-1" style={{
            fontSize: "15px",
            fontWeight: "500",
            color: "#606060",
            transform: "translateY(-50%) translateX(20%)",
            pointerEvents: "none"
        }}>
                    Email
                  </label>
                  <input className="form-control" type="email" id="email" required value={emailInput} onChange={onEmailChange} placeholder={`--${t("loginRegister:enter your email")}--`} style={{
            border: "1px solid #006EA7",
            padding: "10px",
            borderRadius: "10px"
        }}/>
                </div>

                <div className="mb-2 position-relative">
                  <label htmlFor="password" className="position-absolute bg-white px-1" style={{
            fontSize: "15px",
            fontWeight: "500",
            color: "#606060",
            transform: "translateY(-50%) translateX(10%)",
            pointerEvents: "none"
        }}>
                    Password
                  </label>
                  <input className="form-control" type={isPasswordVisible ? "text" : "password"} id="password" required value={passwordInput} onChange={onPasswordChange} placeholder={`--${t("loginRegister:enter your password")}--`} style={{
            border: "1px solid #006EA7",
            padding: "10px",
            borderRadius: "10px"
        }}/>
                  <button type="button" onClick={togglePasswordVisibility} className="position-absolute end-0 top-50 translate-middle-y bg-transparent border-0" style={{ background: "none", cursor: "pointer" }}>
                    {isPasswordVisible ? (<EyeIcon width="20px" height="20px" style={{ color: "#006EA7" }}/>) : (<EyeOffIcon width="20px" height="20px" style={{ color: "#006EA7" }}/>)}
                  </button>
                </div>
                <div className="d-flex justify-content-end">
                  <a href={`${process.env.NEXT_PUBLIC_BASE_ADMIN_URL}/lupa-password`} style={{ fontWeight: "500", fontSize: "15px" }}>
                    {t("loginRegister:forgot password")}?
                  </a>
                </div>
                <input type="submit" className="my-3 btn btn-primary w-100" value={t("common:login")} disabled={isSubmiting} style={{
            borderRadius: "10px",
            fontWeight: "600",
            fontSize: "15px"
        }}/>
              </form>

              <div className="text-center" style={{ color: "#606060" }}>
                {t("loginRegister:don't have an account")}?{" "}
                <Link href={"/register"} 
    // {`${
    //   process.env.NEXT_PUBLIC_BASE_ADMIN_URL
    // }/daftar-member?continue=${encodeURIComponent(
    //   `/masuk?continue=${router.query.continue}`
    // )}`}
    style={{ fontWeight: "600", textDecoration: "underline" }}>
                  {t("loginRegister:register")}
                </Link>
              </div>
            </div>
          </div>
          <div className="d-none col-10 col-md-4 p-4 login-bg-img-new rounded-end-4 d-md-flex justify-content-center">
            <div className="d-flex flex-column justify-content-center card-body text-center">
              <div className="d-flex flex-column gap-3 text-white font-roboto" style={{
            fontStyle: "italic",
            fontWeight: "600",
            fontSize: "15px"
        }}>
                <div>
                  <Image src="/logo_rmd.png" width="200px" height="60px" quality={100} alt="logo rumahdewi" className="mx-auto" placeholder="blur" blurDataURL="/logo_rmd.png"/>
                </div>
                <p>{t("loginRegister:rumahdewicom is the best")}</p>
                <p>
                  {t("loginRegister:you can also find property agents who will assist you in property transactions")}
                </p>
              </div>

              {/* <div className="d-flex gap-2">
          <Image
            src="/app-store.png"
            width="100px"
            height="30px"
            quality={100}
            alt="logo app store"
          />
          <Image
            src="/google-play.png"
            width="100px"
            height="30px"
            quality={100}
            alt="logo google play"
          />
        </div> */}
            </div>
          </div>
        </div>
      </div>
      <FooterAppAds />
      <Footer />
    </div>);
};
function getServerSideProps(context) {
    const cookie = useCookie(context);
    if (cookie.get("token")) {
        return {
            redirect: {
                destination: "/"
            }
        };
    }
    return {
        props: {}
    };
}
export default Login;

    async function __Next_Translate__getServerSideProps__1942b436c81__(ctx) {
      const res = await getServerSideProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/login',
            loaderName: 'getServerSideProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getServerSideProps__1942b436c81__ as getServerSideProps }
  